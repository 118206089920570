<template>
  <div class="py-2 pl-3 pr-2 offer" :class="{ newOffer: !offer.readByCurrentUser }" @click="$emit('open', offer)">
    <div class="d-flex justify-space-between">
      <span class="primary--text body-2" style="font-weight:600"><v-icon color="separator" small class="mr-1"> $package</v-icon>
        {{ offer.load }}t, {{ offer.loadMeters }} ldm</span
      >
      <v-btn v-if="closable" class="rounded mr-1" depressed icon x-small tile @click="$emit('closeOffer')">
        <v-icon size="20"> $closeIcon </v-icon>
      </v-btn>
    </div>
    <div class="d-flex justify-space-between pt-2">
      <v-row no-gutters>
        <v-col cols="6" class="d-flex align-self-center">
          <div class="d-flex align-center">
            <country-flag :country='offer.loadingPlace.country' class="mr-1"/>
            <div class="mr-1">
              <div class="country">
                {{ offer.loadingPlace.country }}, {{ offer.loadingPlace.postalCode }}
              </div>
              <div class="date">
                {{ offer.loadingPlace.city }}
              </div>
              <div class="date">
                {{ offer.start | dateFormat("YYYY-MM-DD HH:mm") }}
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="6" class="d-flex align-self-center">
          <div class="d-flex align-center">
            <template v-if="offer.unloadingPlace">
              <country-flag :country='offer.unloadingPlace.country' class="mr-1"/>
              <div>
                <div class="country" style="font-size:10px">
                  {{ offer.unloadingPlace.country }}, {{ offer.unloadingPlace.postalCode }}
              </div>
              <div class="date">
                {{ offer.unloadingPlace.city }}
              </div>
              <div class="date">
                {{ offer.end | dateFormat("YYYY-MM-DD HH:mm") }}
              </div>
              </div>
            </template>
            <span class="date" v-else>{{ $t('myVehicle.anyDestination') }}</span>
          </div>
        </v-col>
      </v-row>
      <!-- <div class="d-flex align-center">
        <v-btn icon small>
          <v-icon> mdi-dots-vertical </v-icon>
        </v-btn>
      </div> -->
    </div>
  </div>
</template>
<script>
import CountryFlag from 'vue-country-flag'
export default {
  name: 'Offer',
  components: { CountryFlag },
  props: ['offer', 'closable'],
  data () {
    return {
      loading: false
    }
  },
  computed: {},
  methods: {}
}
</script>
<style>
.offer {
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.country {
  color: var(--v-separator-base);
  font-size: 10px;
}
.date {
  font-size: 10px;
}
.offer:hover {
  background-color: var(--v-background-base);
}
.offer:after {
  content: "";
  border-top: 2px solid var(--v-deal-base);
  bottom: 0px;
  left: 15px;
  width: calc(100% - 30px);
  position: absolute;
}
.newOffer {
  background-color: var(--v-background-base);
}
</style>
